export const Name = (props) => (
  <div className={"input-wrapper input-top"}>
    <div className={"input-border"}>
      <input
        id={"name"}
        className={"input-input"}
        placeholder="Enter name"
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      />
    </div>
  </div>
);
