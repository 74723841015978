export const Knock = (props) => (
  <div
    style={{
      position: "absolute",
      width: "100%",
      display: "flex",
      alignItems: "center",
      height: "100vh",
      background: "rgb(.0,0,0,.6)",
    }}
  >
    <div
      style={{
        background: "#FFF",
        width: "calc(100% - 20px ) ",
        marginLeft: "10px",
        borderRadius: "20px",
        position: "relative",
        zIndex: 20,
      }}
      className={"input-wrapper input-message"}
    >
      <div className={"input-border"} style={{ border: "none" }}>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            fontSize: "50px",
            alignItems: "center",
            verticalAlign: "center",
          }}
          id={"knock"}
          className={"input-input input-textarea unselectable"}
          // placeholder="Enter message"
          onClick={props.onChange}
        >
          {props.value}
        </div>
      </div>
    </div>
  </div>
);
