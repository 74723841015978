import { db } from "./constant/fireBase";
import { ref, set, onValue } from "firebase/database";

export const updateData = ({ name, message, url }) => {
  return new Promise(async (reslove, reject) => {
    try {
      await set(ref(db, url), { name, message });
      reslove(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const bindData = async ({ url, callBack }) => {
  onValue(ref(db, url), (snapshot) => {
    const data = snapshot.val();
    callBack({ data, url });
  });
};
