export const Message = (props) => (
  <div className={"input-wrapper input-message"}>
    <div className={"input-border"}>
      <textarea
        id={"message"}
        className={"input-input input-textarea"}
        placeholder="Enter message"
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      />
    </div>
  </div>
);
