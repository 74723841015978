export const Button = (props) => (
  <div className={"button-wrapper"}>
    <div
      className={"button unselectable"}
      onClick={!props.disable ? props.onChange : () => {}}
      id={props._id}
      style={props.style}
    >
      {" "}
      {props.value}
    </div>
  </div>
);
