export const Title = ({ status }) => (
  <div
    style={{
      flexDirection: "column",
      display: "flex",
      flex: "1",
      justifyContent: "center",
      alignContent: "center",
      justifyItems: "center",
      alignItems: "center",
      fontFamily: "led16",
    }}
  >
    <div
      style={{
        background: "#5f5f5f",
        padding: "10px 20px",
        borderRadius: "30px",
        border: "2px solid #FFFF ",
      }}
      className="norman-text unselectable"
    >
      <span style={{ color: "#FFF" }}> STATUS | </span>{" "}
      <span style={{ color: "#47FF00" }}> {status} </span>
    </div>
  </div>
);
