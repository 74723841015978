export const Busy = (props) => (
  <div
    style={{
      position: "absolute",
      width: "100%",
      display: "flex",
      alignItems: "center",
      height: "100vh",
      background: "rgb(.0,0,0,.6)",
    }}
  >
    <div
      style={{
        background: "#FFF",
        width: "calc(100% - 20px ) ",
        marginLeft: "10px",
        // borderRadius: "20px",
        top: "20px",
        position: "relative",
        flex: 1,
        height: "50vh",
        transform: `rotate(-5deg)`,

        zIndex: 20,
      }}
      className={"input-wrapper input-message"}
    >
      <div
        className={"input-border"}
        style={{ border: "none", padding: "1px" }}
      >
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            fontSize: "40px",
            alignItems: "center",
            verticalAlign: "center",
            flexDirection: "column",
          }}
          id={"knock"}
          className={"input-input  unselectable"}
        >
          <div>
            {" "}
            NORMAN ISN’T LISTENING FOR MESSAGES OR JOKE REQUESTS RIGHT NOW.
          </div>

          <br />
          <div>BACK SHORTLY.</div>
        </div>
      </div>
    </div>
  </div>
);
