import { useState, useEffect } from "react";
import { Title } from "./1-atoms/Title";
import { Name } from "./1-atoms/Name";
import { Message } from "./1-atoms/Message";
import { Button } from "./1-atoms/Button";
import { updateData, bindData } from "../../Controllers/index";
import { Knock } from "./1-atoms/Knock";
import { v4 as uuidv4 } from "uuid";
import { Headline } from "./1-atoms/Headline";
import { Busy } from "./1-atoms/Busy";
const urls = {
  status: "/norman/status",
  knock: "/normanweb/knock",
  knockJOKEID: "/jokeID/knock",
  message: "/norman/message",
};
function App() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [textButton, setTextButton] = useState("send");
  const [status, setStatus] = useState("");
  const [knock, setKnock] = useState("");
  const [jokeId, setJokeId] = useState(false);
  const callBack = ({ data, url }) => {
    switch (url) {
      case urls.status:
        setStatus(data);
        return;
      case urls.knock: {
        const { message } = data;
        setKnock(message);
        return;
      }
      default:
        console.error("unknow interaction");
        break;
    }
  };

  useEffect(() => {
    bindData({ url: urls.knock, callBack });
    bindData({ url: urls.status, callBack });
  }, []);

  const onChange = async (event) => {
    const { id, value } = event.currentTarget;

    switch (id) {
      case "message":
        setMessage(value);
        return;
      case "name":
        setName(value);
        return;
      case "knockStart": {
        await updateData({
          name: "",
          message: uuidv4(),
          url: urls.knockJOKEID,
        });
        return;
      }

      case "knock": {
        await updateData({
          name: "",
          message: "",
          url: urls.knock,
        });

        setTimeout(async () => {
          await updateData({
            name: "",
            message: uuidv4(),
            url: urls.knockJOKEID,
          });
        }, 200);

        return;
      }
      case "button": {
        try {
          await updateData({ name, message, url: urls.message });
          setName("");
          setMessage("");
          setTextButton("sending message");
          setTimeout(() => {
            setTimeout(() => {
              setTextButton("send");
            }, 2000);

            setTextButton("message sent");
          }, 2000);
          return;
        } catch (error) {
          setTextButton(error.message);
          return;
        }
      }
      default:
        console.error("unknow interaction ");
        break;
    }
  };

  console.log("||||", knock === "", "||||", "knock");

  const backMoment = `NORMAN ISN’T LISTENING FOR MESSAGES OR JOKE REQUESTS RIGHT NOW. HE’S
  BUSY DOING SOMETHING ELSE. BACK SHORTLY.`;

  return (
    <div className="app-wrapper">
      <div className="app">
        <Headline></Headline>

        {textButton !== "send" && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              alignItems: "center",
              height: "100vh",
              background: "rgb(.0,0,0,.6)",
            }}
          ></div>
        )}
        {status == "BUSY" && <Busy value={backMoment} />}
        {knock !== "" && <Knock onChange={onChange} value={knock} />}
        <Title status={status} />
        <div
          style={{
            fontSize: "1.6rem",
            fontFamily: "led16",
            marginTop: "20px",
            color: "white",
          }}
        >
          SEND NORMAN A MESSAGE{" "}
        </div>
        <Name
          onChange={onChange}
          value={name}
          disable={knock !== "" || status == "BUSY" ? true : false}
        />
        <Message
          onChange={onChange}
          value={message}
          disable={knock !== "" || status == "BUSY" ? true : false}
        />

        <Button
          disable={knock !== "" || status == "BUSY" ? true : false}
          onChange={onChange}
          value={textButton}
          _id={"button"}
          style={{ position: "reletive", zIndex: knock === "" ? 0 : 10 }}
        />
        <div
          style={{
            fontSize: "1.6rem",
            fontFamily: "led16",
            marginTop: "30px",
            color: "white",
          }}
        >
          ASK NORMAN TO{" "}
        </div>

        <Button
          disable={knock !== "" || status == "BUSY" ? true : false}
          onChange={onChange}
          value={"tell a joke"}
          _id={"knockStart"}
          style={{ position: "reletive", zIndex: knock === "" ? 10 : 0 }}
        />
      </div>
    </div>
  );
}

export default App;
