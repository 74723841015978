import React from "react";
import { v4 as uuidv4 } from "uuid";
const chars = [
  { char: "N", color: "#FF0000", id: uuidv4() },
  { char: "O", color: "#FFA300", id: uuidv4() },
  { char: "R", color: "#FFE300", id: uuidv4() },
  { char: "M", color: "#2DFF00", id: uuidv4() },
  { char: "A", color: "#0089FF", id: uuidv4() },
  { char: "N", color: "#C000FF", id: uuidv4() },
];

const subLines = [
  "Normative Operational",
  "Responsive Machine ",
  "Alpha Numeric",
];

export const Headline = (props) => {
  return (
    <>
      <div
        style={{
          padding: "20px",
          flexDirection: "column",
          display: "flex",
          flex: "1",
          justifyContent: "center",
          alignContent: "center",
          justifyItems: "center",
          alignItems: "center",
          fontFamily: "led16",
        }}
      >
        <div style={{ fontSize: "3rem" }}>
          {" "}
          {chars.map((_span) => (
            <span style={{ color: _span.color }} key={`title-${_span.id}`}>
              {_span.char}{" "}
            </span>
          ))}{" "}
        </div>
        {subLines.map((subline) => (
          <div style={{ color: "#DEDEDE" }} key={subline}>
            {subline}
          </div>
        ))}
      </div>
    </>
  );
};
