import { initializeApp } from 'firebase/app';

import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyBMHr97aEudErJ8d5RZXKOik85LVFMnHvU',
  authDomain: 'alphanumeric-29823.firebaseapp.com',
  databaseURL:
    'https://alphanumeric-29823-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'alphanumeric-29823',
  storageBucket: 'alphanumeric-29823.appspot.com',
  messagingSenderId: '1073699988438',
  appId: '1:1073699988438:web:64bb8a8f4aa8b5de983a77',
  measurementId: 'G-EWG6G62W0D',
};

export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
